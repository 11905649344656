import {Toast} from 'vant'
export default {
  data () {
    return {
      show:false,
      params:{
        token:this.$global.token,
        content:''
      },

      apis:{
        findCicerone:'/cicerone/findCicerone',//导游列表
        enumsApi:'/enums/list',
        add:'/userCriticism/insertUserCriticism',//添加评论
      },
      content:{},

      message:"",
      bizTypes:5,
      enumList:[],
      count:0,

    }


  },
  created () {
    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
    const me = this;
    me.$set(me.params,'ciceroneId',me.$route.query.bizId);

    me.init();//初始化个人资料
    me.enum();//枚举



  },
  methods: {



    praise(){
      this.init();
    },
    commetFunc(i){
        this.showInp=i;
        this.params.content='';
    },
    collect(){
      this.init();
    },
    bridayFunc(year){
      let date = new Date()
      let tYear = date.getFullYear();

      return tYear-year.slice(0,4)

    },
    enum(){
      const me = this;
      console.log(me.params);
      me.$get({
        url:me.apis.enumsApi,
        params:{
          key:'nationType'
        }
      }).then(rep=>{
        console.log(rep);
        me.enumList = rep
      })
    },
    init () {
      const me = this;
      console.log(me.params);
      me.$get({
        url:me.apis.findCicerone,
        params:me.params
      }).then(rep=>{
        me.content = rep
      })
    },

  }


}
